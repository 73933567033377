import React, { useEffect, useRef, useState } from 'react';
import { DayPilot, DayPilotScheduler } from "daypilot-pro-react";
import axios from "axios";
import { BASE_URL } from "constants";

const Scheduler = (props) => {
  const schedulerRef = useRef();
  const [config, setConfig] = useState({
    timeHeaders: [{"groupBy":"Year"},{"groupBy":"Month"},{"groupBy":"Day","format":"d"}],
    scale: "Day",
    days: DayPilot.Date.today().daysInYear(),
    startDate: DayPilot.Date.today().firstDayOfYear(),
    timeRangeSelectedHandling: "Enabled",
    onTimeRangeSelected: async (args) => {
      const dp = args.control;
      const modal = await DayPilot.Modal.prompt("Create a new Project:", "Project 1");
      dp.clearSelection();
      if (modal.canceled) { return; }
      dp.events.add({
        start: args.start,
        end: args.end,
        id: DayPilot.guid(),
        resource: args.resource,
        text: modal.result
      });
    },
    eventMoveHandling: "Update",
    onEventMoved: (args) => {
      args.control.message("Event moved: " + args.e.text());
    },
    eventResizeHandling: "Update",
    onEventResized: (args) => {
      args.control.message("Event resized: " + args.e.text());
    },
    eventDeleteHandling: "Update",
    onEventDeleted: (args) => {
      args.control.message("Event deleted: " + args.e.text());
    },
    eventClickHandling: "Disabled",
    eventHoverHandling: "Bubble",
    bubble: new DayPilot.Bubble({
      onLoad: (args) => {
        args.html = "From  "+args.source.data.start.toString("yyyy/MM/dd") + "<br />" +"To  "+args.source.data.end.toString("yyyy/MM/dd") + "<br />" +  args.source.data.text + "<br />" +  args.source.data.name;
      }
    }),
    treeEnabled: true,
  });
  const [employeeData, setEmployeeData] = useState([]);
  const [records, setRecord] = useState([]);


  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/project/projectByStatus/${1}`);
        setRecord(res.data);
      } catch (err) {
        console.error('Error fetching project data:', err);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    // Group projects by engineer
    const engineerProjects = {};
    records.forEach(output => {
      const engineer = output.Engineer;
      if (!engineerProjects[engineer]) {
        engineerProjects[engineer] = [];
      }
      engineerProjects[engineer].push(output);
    });
  
    // Initialize events and resources arrays
    const events = [];
    const resources = [];
  
    // Populate events and resources arrays
    Object.entries(engineerProjects).forEach(([engineer, projects], index) => {
      resources.push({
        name: engineer,
        id: index + 1,
        expanded: true,
        children: projects.map(project => ({
          name: project.ProjectName,
          id: project.ProjectID,
          barColor: project.BarColor || '#008000',
          row: { color: '#000000' }
        }))
      });
    
      projects.forEach((project, projectIndex) => {
        // Convert the TargetFinishDate to a Date object
        const targetFinishDate = new Date(project.TargetFinishDate);
        const today = new Date();
        const isPastDue = targetFinishDate < today;
  
        events.push({
          id: events.length + 1, // Ensure unique IDs for events
          name: engineer,
          text: project.ProjectName,
          start: new Date(project.StartDate).toISOString().split('T')[0],
          end: new Date(project.TargetFinishDate).toISOString().split('T')[0], // Use the modified end date
          resource: project.ProjectID,
          barColor: isPastDue ? '#FF5733' : project.BarColor || '#008000' // Set color based on past due status
        });
      });
    });
  
    // Update the config state with events and resources
    setConfig(prevConfig => ({
      ...prevConfig,
      events,
      resources
    }));
  }, [records]);

  const getScheduler = () => schedulerRef.current?.control;

  return (
  <>
      <div>
        <DayPilotScheduler
          {...config}
          ref={schedulerRef}
        />
      </div>
     
      </> 
  );
}

export default Scheduler;
