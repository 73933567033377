
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import Details from "layouts/authentication/userDetails";
import { BASE_URL } from "constants";

export default function Users() {
  
  const[records,setRecord] = useState([])
      useEffect(() => {
        const fetchStok = async () => {
          try {
            const res = await axios.get(`${BASE_URL}/auth/allUsers`);
            setRecord(res.data);        
          } catch (err) {
            console.log(err);
          }
        };
        fetchStok();
      }, []);
  const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'idUser', headerName: 'UserID', width: 100 },
        { field: 'empNo', headerName: 'EmpNo', width: 150 },
        { field: 'username', headerName: 'User Name', width: 150 },        
        { field: 'email', headerName: 'Email',  width: 300},
        { field: 'AccStatus', headerName: 'Acc Status',  width: 150},
        {field: 'Details',headerName: 'Details',          
        renderCell: (cellValues) => {           
          return <Details data= {cellValues.row} />  
        } ,width: 150},
        
      ];
      
        const rows = records.map((output,index)=> ({
          id: index+1,
          idUser:output.id,
          empNo: output.empNo,
          username:output.username,
          email: output.email,
          AccStatus: output.accstatus,
          Status: output.status,
        
        }))
      console.log(records);
  return (
    <DashboardLayout>
    <DashboardNavbar />
    <div  >
      <h4>Users</h4>
             <DataGrid  
            rows={rows}
            columns={columns}
            initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            pageSizeOptions={[10, 20]}
            checkboxSelection
          />   
           </div>  
                  
    
    </DashboardLayout>
  );
}