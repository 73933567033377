import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "constants";
import Scheduler from "./Scheduler";

export default function TimeLine() {
  const [value, setValue] = React.useState('1');
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/project/ProjectEngVice`);
        setRecords(res.data);
        setLoading(false);
        // Set the value to the value of the first tab
        if (res.data.length > 0) {
          setValue(res.data[0].CurrentStatus);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchStock();
  }, []);

  return (
    <DashboardLayout>
      <br/>
      <h4>Timeline</h4>
      {/* {records.map((record, index) => (
     <Scheduler data={record}/>
      ))} */}
      <Scheduler/>
    </DashboardLayout>
  );
}
