

import { useState } from "react";

// react-router-dom components
import { Link,useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { AuthContext } from "context/authContext";
import { useContext } from "react";
const Login = () => {
  const [inputs, setInputs] = useState({
    userName: "",
    password: "",
   
  });
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [error,setError] = useState(false)

  const navigate = useNavigate();
 const { login } = useContext(AuthContext);
  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await login(inputs)
      navigate("/personal");
    } catch (err) {
      setError(err.response.data);
    }
  };
  if(!error){
    return (
      <BasicLayout >
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <Grid item xs={2}>
            <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                 
                </MDTypography>
              </Grid>
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              3S FABRICATIONS
            </MDTypography>
            <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
             
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput type="text" label="User Name"  variant="standard" name="userName" fullWidth onChange={handleChange}/>
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="password" label="Password"   variant="standard" name="password" fullWidth onChange={handleChange} />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" onClick={handleSubmit}  onKeyDown={e => e.key === 'Enter' ? handleSubmit:''} >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    );
  }
  else
  {
    return (
      <BasicLayout >
        <Card>
          <MDBox
            variant="gradient"
            bgColor="error"
            borderRadius="lg"
            coloredShadow="error"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <Grid item xs={2}>
            <MDTypography component={MuiLink} href="#" variant="body1" color="error">
              
                </MDTypography>
              </Grid>
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              3S FABRICATIONS
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
              {error}
            </MDTypography>
            <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
             
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput type="text" label="User Name"  variant="standard" name="userName" fullWidth onChange={handleChange}/>
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="password" label="Password"   variant="standard" name="password" fullWidth onChange={handleChange} />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="error" onClick={handleSubmit}  onKeyDown={e => e.key === 'Enter' ? handleSubmit:''} >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="error"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    );
  }
 
}

export default Login;
