import * as React from 'react';
import Button from '@mui/material/Button';
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Checkbox from '@mui/material/Checkbox';
import { BASE_URL } from "constants";

export default function Permition(props) {
  let MuserId=props.data.UMserId;
  let iduserPer=props.data.output.iduserPermission;
  let routId=props.data.output.idroutes;
  let userId=props.data.output.userId;
  let route=props.data.output.routeName;
  let viwe=props.data.output.Viwe;
  let add=props.data.output.Add;
  let edit=props.data.output.Edit;
  let deleteR =props.data.output.Delete;
  // props.data.map((userRout)=>{
  //   route=userRout.routeName
  //    viwe=userRout.Viwe
  // add=userRout.Add
  // edit=userRout.Edit
  // deleteR =userRout.Delete
  // })

 
  const [setError] = useState(false)
 

  const [Viwe, setCheckedViwe] = React.useState((viwe==='1'),true,(viwe===''),false); 
  const handleChangeViwe =  (event) => {
    setCheckedViwe(event.target.checked);  
  };

  const [Add, setCheckedAdd] = React.useState((add==='1'),true,);
  const handleChangeAdd = (event) => {
    setCheckedAdd(event.target.checked);
  };

  const [Edit, setCheckedEdit] = React.useState((edit==='1'),true,); 
  const handleChangeEdit = (event) => {
    setCheckedEdit(event.target.checked);
  };

  const [Delete, setCheckedDelete] = React.useState((deleteR==='1'),true,); 
  const handleChangeDelete = (event) => {
    setCheckedDelete(event.target.checked);
  };
  const [recode, setrecode] = useState({
    Viwe:viwe,
    Add:add,
    Edit:edit,
    Delete:deleteR,
    Route:route,
    MuserId:MuserId,
   
  });
  const [recodeUpadte, setrecodeUpdate] = useState({
    Viwe:viwe,
    Add:add,
    Edit:edit,
    Delete:deleteR,

   
  });
  const[recordsPermission,setPermissionRecord] = useState([])
  useEffect(() => {
    const fetchStok = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/auth/allRoute`);
        setPermissionRecord(res.data);        
      } catch (err) {
        console.log(err);
      }
    };
    fetchStok();
  }, []);
 

  recode.Viwe=Viwe
  recode.Add=Add
  recode.Edit=Edit
  recode.Delete=Delete
   recode.Route=routId
 
   recodeUpadte.Viwe=Viwe
   recodeUpadte.Add=Add
   recodeUpadte.Edit=Edit
   recodeUpadte.Delete=Delete
  const handleClickSave = async (e) => {
    e.preventDefault();
    try {
      if(userId == null ||userId == "")
      {
      axios.post(`${BASE_URL}/auth/permission/${userId}/${routId}`, recode);

      }
      else if(iduserPer!="")
      {
        axios.post(`${BASE_URL}/auth/permissionUpadte/${iduserPer}`, recodeUpadte);
      }
      
      
    } catch (err) {
      console.log(err);
      setError(true)
    }
  
  };
  return (
    <div>
     
         
        <Grid container spacing={3}>
           
        <Grid item xs={12} md={12} lg={10}>
        <MDBox >
        
         <table className="table" width='100%'>
        <thead >
          <tr>
          <th width= "20"></th>        
          <th width= "90"></th> 
          <th width= "75"></th> 
          <th width= "65"></th> 
          <th width= "65"></th> 
          <th width= "80"></th> 
        
            </tr>
        </thead>    
           
        <tbody>
      
            {recordsPermission.filter(outputu => {
        return (
          outputu.routeName===route
        );
        }).map((output,index)=> 
         
         <tr>
        
          <td>{output.idroutes}</td>           
           <td>{output.routeName}</td>  
          
           <td ><Checkbox checked={Viwe}  onClick={handleChangeViwe}/>Viwe</td>
           <td><Checkbox checked={Add}  onChange={handleChangeAdd}/>Add</td>
           <td><Checkbox checked={Edit}  onChange={handleChangeEdit}/>Edit</td>
           <td><Checkbox checked={Delete}  onChange={handleChangeDelete}/>Delete</td>
           
       </tr>)} 
        </tbody>
     </table> 
     </MDBox>
    
        </Grid> 
       
        <Grid item xs={12} md={12} lg={2}> 
        <MDBox>
     <Button onClick={handleClickSave}>Update</Button>
     </MDBox>
     </Grid>
        </Grid>
         
    </div>
  );
}
