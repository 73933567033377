import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OpenProjectEngVice from "./openProjectEngVice";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "constants";
import { useParams } from "react-router-dom";
import ImageXL from './imageXL';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export default function ProjectEngVice(props) {
  const { Engineer } = useParams();
  const [value, setValue] = React.useState('1');
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/project/ProjectStatusCountEngVice/${Engineer}`);
        setRecords(res.data);
        setLoading(false);
        // Set the value to the value of the first tab
        if (res.data.length > 0) {
          setValue(res.data[0].CurrentStatus);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchStock();
  }, []);

  return (
    
    <DashboardLayout>
      <br/>
      <h4>Engineer</h4>
      <ImageXL data={Engineer}/>
<br/>
      {records.map((record, index) => (
        <div key={record.POType}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography>
              <table colspan="20">
                <tr>
                  <th  width= "125" align="left">
                  {record.CurrentStatusName} 
                  </th>
                  <th width= "100" align="left">
                 ({record.count})
                  </th>                 
                </tr>
              </table>
                 
             </Typography>
            </AccordionSummary>
            <OpenProjectEngVice data={record}/>
            <AccordionDetails>
              <Typography>
               
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}

    </DashboardLayout>
    
  );
}
