import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { BASE_URL } from "constants";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

function ProjectDash() {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchStock = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/project/ProjectStatusCount`);
        setRecords(res.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchStock();
  }, []);
console.log(records);
const handleClickStock = async (e) => {
  e.preventDefault();
  try {
   navigate("/project");
  } catch (err) {
    console.log(err);  
  }  
  };
  
  const transformedRecords = records.map(record => {
    if (record.CurrentStatusName === 'Open') {
      return { ...record, icon: 'launch' };
    } else if (record.CurrentStatusName === 'Started') {
      return { ...record, icon: 'start' };
    } else if (record.CurrentStatusName === 'Cancel') {
      return { ...record, icon: 'cancel' };
    } else if (record.CurrentStatusName === 'Complete') {
      return { ...record, icon: 'task' };
    } else {
      // Handle any other condition here
      return { ...record, icon: 'back_hand' }; // Example of setting a default icon
    }
  });
  return (
    <>
  <div>
Projects
  </div>
  <br/>
  <br/>
    
  <MDBox py={3}>
        <Grid container spacing={3}>
          {transformedRecords.map((record, index) => (
            <Grid key={index} item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon={record.icon}
                  title={record.CurrentStatusName}
                  count={record.count}
                  percentage={{
                    color: "info",
                    amount: "",
                    label: "",
                  }}
                />
              </MDBox>
            </Grid>
          ))}
          
        </Grid>
        </MDBox>
        <MDButton variant="gradient" color="info" fullWidth onClick={handleClickStock} >
            Projects List
        </MDButton>
      </>
  );
}

export default ProjectDash;
