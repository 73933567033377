import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { useMaterialUIController, setOpenConfigurator } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { AuthContext } from "context/authContext";
import{BASE_URL}from "./constants";
import SignIn from './layouts/authentication/sign-in/index';
import SignUp from './layouts/authentication/sign-up/index';

export default function App() {
  const { currentUser } = useContext(AuthContext);
  const userId = currentUser?.id || '';
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/auth/userPermition/${userId}`);
        setPermissions(res.data);
      } catch (err) {
        console.error("Error fetching user permissions:", err);
      }
    };

    if (userId) {
      fetchData();
    } else if (pathname !== "/authentication/sign-in" && pathname !== "/authentication/sign-up") {
      navigate('/authentication/sign-in');
    }
  }, [userId, navigate, pathname]);

  const renderRoutes = (allRoutes) => {
    return allRoutes.map((route) => {
      if (!userId && (route.route === "/authentication/sign-in" || route.route === "/authentication/sign-up")) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      } else if (userId && permissions.some(permission => permission.routeName === route.name)) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <Routes>
        <Route path="/authentication/sign-in" element={<SignIn />} />
        <Route path="/authentication/sign-up" element={<SignUp />} />
        {renderRoutes(routes)}
      </Routes>
      <CssBaseline />
      {layout === "dashboard" && userId && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="3S FABRICATIONS"
            routes={routes}
            userId={userId}
          />
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={handleConfiguratorOpen}
      >
        <Icon fontSize="small" color="inherit">
          settings
        </Icon>
      </MDBox>
    </ThemeProvider>
  );
}
