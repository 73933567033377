
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProjectEng from "layouts/project/projectEng";
// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import OrdersOverview from "layouts/personal/components/OrdersOverview";
import ProjectDash from "layouts/project/projectDash";
function Personal() {
  const { sales, tasks } = reportsLineChartData;
  
  return (
    <DashboardLayout>
      <DashboardNavbar />   
       <ProjectDash/>
       <br/>
       <br/>
<ProjectEng/>
    </DashboardLayout>
  );
}
export default Personal;
