import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from 'constants';

function Image(props) {
  const [accStatus, setAccStatus] = useState(props.data.Status === '1');
  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/employee/employee/${props.data.Engineer}`);
        setEmployeeData(res.data);
      } catch (err) {
        console.error('Error fetching employee data:', err);
      }
    };

    fetchEmployeeData();
  }, [props.data.Engineer]);

  const engineerId = props.data.Engineer;
  const format = '.JPG';
  const imageUrl = `${process.env.PUBLIC_URL}/assets/${engineerId}${format}`;

  return (
    <div>
      <img
        src={imageUrl}
        alt="profile-image"
        width="45"
        height="45"
        style={{
          borderRadius: '50%', // Makes the image circular
          border: '2px solid #ccc', // Adds a border with a light gray color
        }}
        onError={(e) => {
          e.target.onerror = null; // Prevents infinite loop
          e.target.src = `${process.env.PUBLIC_URL}/assets/Noimg.jpg`; // Default "no image" placeholder
        }}
      />
      {employeeData && employeeData.SalaryName}
    </div>
  );
}

export default Image;
