

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App.js";
import { AuthContexProvider } from "./context/authContext";

import { MaterialUIControllerProvider } from "context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const container = document.getElementById("app");
const root = createRoot(container);
const queryClient = new QueryClient();
root.render(
  <BrowserRouter >
    <MaterialUIControllerProvider>
    <AuthContexProvider>
    <QueryClientProvider client={queryClient}>
      <App />
      </QueryClientProvider>
      </AuthContexProvider> 
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
