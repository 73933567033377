import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OpenProject from "./openProject";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "constants";

export default function It() {
  const [value, setValue] = React.useState('1');
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/project/ProjectStatusCount`);
        setRecords(res.data);
        setLoading(false);
        // Set the value to the value of the first tab
        if (res.data.length > 0) {
          setValue(res.data[0].CurrentStatus);
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchStock();
  }, []);

  return (
    <DashboardLayout>
      <br/>
      <h4>Projects</h4>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {records.map((record, index) => (
                <Tab key={index} label={record.CurrentStatusName} value={record.CurrentStatus} />          
              ))}        
            </TabList>
          </Box>
          {records.map((record, index) => (
            <TabPanel key={index} value={record.CurrentStatus}>       
              <OpenProject data={record}/>
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </DashboardLayout>
  );
}
