
import * as React from 'react';

export default function ProjectSumm(props) {
    let Open=props.data.Open;
    let Started=props.data.Started;
    let Complete=props.data.Complete;
    let Hold=props.data.Hold;
    let Cancel=props.data.Cancel;
  return (
   
    <div  >      
        <table>
  <thead>
  <tr>
      <th style={{ width: '20px' }}></th>
      <th style={{ width: '20px' }}></th>
      <th style={{ width: '20px' }}></th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <td>
        <div>Open</div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div>{Open}</div>
      </td>
    </tr>
    <tr>
      <td>
        <div>Started</div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div>{Started}</div>
      </td>
    </tr>
    {/* <tr>
      <td>
        <div>Complete</div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div>{Complete}</div>
      </td>
    </tr> */}
    {/* <tr>
      <td>
        <div>Hold</div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div>{Hold}</div>
      </td>
    </tr> */}
    {/* <tr>
      <td>
        <div>Cancel</div>
      </td>
      <td>
        <div></div>
      </td>
      <td>
        <div>{Cancel}</div>
      </td>
    </tr> */}
  </tbody>
</table>
           </div>  
                  
    
   
  );
}