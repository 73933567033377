import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from "components/MDButton";
import { useState } from "react";
import axios from "axios";
import MDInput from "components/MDInput";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import Avatar from '@mui/material/Avatar';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Permission from "layouts/authentication/userPermissionGet";
import { BASE_URL } from "constants";
export default function FormDialog(props) {

  const [accStatus, setChecked] = React.useState((props.data.Status==='1'),true,);
  const [recode, setrecode] = useState({
    status:accStatus

  });

  let Id=props.data.empNo
  let UMserId=props.data.idUser
  recode.status=accStatus
  
  
  const [error,setError] = useState(false)
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
    
  };
  const handleClickSave = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${BASE_URL}/auth/activeAcc/`+Id, recode);
      
    } catch (err) {
      console.log(err);
      setError(true)
    }
    setOpen(false);
  };
  const[records,setRecord] = useState([])
  useEffect(() => {
    const fetchStok = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/auth/userDetails/`+Id);
        setRecord(res.data);        
      } catch (err) {
        console.log(err);
      }
    };
    fetchStok();
  }, []);
  const handleChange1 = (e) => {
    setrecode((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const[recordsUserPermission,setUserPermissionRecord] = useState([])
  useEffect(() => {
    const fetchStok = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/auth/userPermitionAll/`+props.data.idUser);
        setUserPermissionRecord(res.data);        
      } catch (err) {
        console.log(err);
      }
    };
    fetchStok();
  }, []);
 
  const user=Id;
  const format=".JPG";
 const image= user+format;
  return (
    <div>
       
      <MDButton type="button" variant="gradient" color="info" onClick={handleClickOpen}  >
      Details
            </MDButton>   
           
      <Dialog open={open}  fullWidth='xl'>
        
        <DialogContent>
        {records.map((output,index) => ( 
        <Grid container spacing={3}>
           <Grid item xs={12} md={12} lg={4.5}>
           <img src={`${process.env.PUBLIC_URL}/assets/${image}`} alt="profile-image" width="200" height="200" />

           </Grid>
        <Grid item xs={12} md={12} lg={7.5}>
        <MDBox mb={0.5}>
        <MDInput type="label" label="Emp No" value={output.EmpNo}  variant="standard" name="EmpNo" disabled="true"  fullWidth='xl'   />
        </MDBox>
        
        
        <MDBox mb={0.5}>
        <MDInput type="label" label="Name In Full" value={output.NameInFull}  variant="standard" name="NameInFull" disabled="true"   fullWidth='xl' />
        </MDBox>
      
        
        <MDBox mb={0.5}>
        <MDInput type="label" label="Department" value={output.Department}  variant="standard" name="Department" disabled="true" fullWidth='xl'    />
        </MDBox>
        
        
        <MDBox mb={0.5}>
        <MDInput type="label" label="Designation" value={output.Designation}  variant="standard" name="Designation" disabled="true"  fullWidth='xl'   />
        </MDBox>
       
       
        <MDBox mb={0.5}>
        <MDInput type="label" label="Job Status" value={output.Status}  variant="standard" name="JobStatus" disabled="true"  fullWidth='xl'  />
        </MDBox>
        <MDBox mb={0.5}>
        <MDInput type="label" label="Account Status" value={accStatus}  variant="standard" name="status" disabled="true"  fullWidth='xl'  onChange={handleChange1}  />
        </MDBox>
       
       
        </Grid>
        </Grid>
         ))}
         
          <FormControlLabel control={<Switch
      checked={accStatus}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />} label="Active" />
<Grid container spacing={3}>
           <Grid item xs={12} md={12} lg={12}>
           <MDBox mb={0.5}>
        {recordsUserPermission.map((output,index)=>
       <Permission data= {{output,UMserId}} /> 
       )}
        </MDBox>
           </Grid>
           </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClickSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
