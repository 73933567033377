
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import Users from "layouts/authentication/users";
import Tables from "layouts/tables";




import Personal from "layouts/personal";
import Project from"layouts/project";
import ProjectEngVice from "layouts/project/projectEngVice";
import Timeline from "layouts/timeline"

import Icon from "@mui/material/Icon";
  const routes = 
  [ 

  {
    type: "collapse",
    name:"Profile",
    key: "personal",
    icon: <Icon fontSize="small">personal</Icon>,
    route: "/personal" ,
    component: <Personal/> ,
  }
  ,  
  {
    type: "collapse",
    name: "Users",
    key: "Users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: <Users />,
  },
 
  {
    type: "collapse",
    name: "Projects",
    key: "Projects",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/project",
    component: <Project/>,
  },
  {  
    name: "ProjectEngVice",
    key: "ProjectEngVice",   
    route: "/projectEngVice/:Engineer",
    component: <ProjectEngVice/>,
  },
  {
    type: "collapse",
    name: "TimeLine",
    key: "TimeLine",
    icon: <Icon fontSize="small">timeline</Icon>,
    route: "/timeline",
    component: <Timeline/>,
  },
  { 
    name:"tables",  
    key: "tables",
    route: "/tables",
    component: <Tables/>,
  },
  
  {
    name:"profile", 
    key: "profile",
    route: "/profile",
    component: <Profile />,
  },
   {
    name:"sign-in", 
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  
  {
    name:"sign-up", 
    key: "sign-up",
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
 
 
] ;

export default routes;
