
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import More from './more';
import Image from './image';
import { BASE_URL } from "constants";

export default function OpenProjectEngVice(props) {
    let Status=props.data.CurrentStatus;
    let Engineer=props.data.ProjectEngineer;
  const[records,setRecord] = useState([])
      useEffect(() => {
        const fetchStok = async () => {
          try {
            const res = await axios.get(`${BASE_URL}/project/engProjectByStatus/${Status}/${Engineer}`);
            setRecord(res.data);        
          } catch (err) {
            console.log(err);
          }
        };
        fetchStok();
      }, []);
  const columns = [
        { field: 'id', headerName: 'ID', width: 75 },
        { field: 'ProjectID', headerName: 'Project ID', width: 150 },
        { field: 'ProjectName', headerName: 'Project Name', width: 250 },       
        { field: 'Customer', headerName: 'Customer', width: 350 },       
         
        { field: 'StartDate', headerName: 'StartDate',  width: 125},
        { field: 'TargetFinishDate', headerName: 'Target Date',  width: 125},
        { field: 'More',headerName: 'More',          
        renderCell: (cellValues) => {           
          return <More data= {cellValues.row} />  
    } ,width: 100},
    
        
      ];
      
        const rows = records.map((output,index)=> ({
          id: index+1,
          ProjectID:output.ProjectID,
          ProjectName: output.ProjectName,
          Description:output.Description,
          Customer: output.Customer,
         
         
          StartDate: new Date(output.StartDate).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).split('/').reverse().join('-') ,
        TargetFinishDate : new Date(output.TargetFinishDate).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).split('/').reverse().join('-'),
        
        
        }))
      console.log(records);
  return (
    <>
   
    <div  >      
             <DataGrid  
            rows={rows}
            columns={columns}
            initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
            checkboxSelection
          />   
           </div>  
                  
           </>
   
  );
}