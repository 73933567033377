
import { Link,useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import axios from "axios";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { BASE_URL } from "constants";
// Images

const Add = () => {
  const [recode, setrecode] = useState({
    userName: "",
    email: "",
    password: "",
    status: "0",
    empNo: "",
    
  });
  const [error,setError] = useState(false)

  const navigate = useNavigate();

  const handleChange = (e) => {
   
    setrecode((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if(!recode.userName || !recode.email || !recode.empNo || !recode.password){
      setError("Complete the Application")
  }
  else{
    try {
      await axios.post(`${BASE_URL}/auth/register`, recode);
      navigate("/authentication/sign-in");
    } catch (err) {
      console.log(err);
      setError(err)
    }
  }

  };
if(!error){
  return (
    <BasicLayout >
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="User Name" variant="standard"   name="userName" fullWidth onChange={handleChange} required  helperText="Can't be empty" />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type={"email"} label="Email" variant="standard"  name="email" fullWidth onChange={handleChange} required helperText="Must be a Valid Email" />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Emp No" variant="standard"  name="empNo" fullWidth onChange={handleChange} required  helperText="Your Employee Number"/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" variant="standard"  name="password" fullWidth onChange={handleChange} required helperText="Can't be empty" />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleClick}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      </BasicLayout >
  );
}
else
{
  return (
    <BasicLayout >
      <Card>
        <MDBox
          variant="gradient"
          bgColor="error"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
           {error}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="User Name" variant="standard"   name="userName" fullWidth onChange={handleChange} required  helperText="Can't be empty" />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type={"email"} label="Email" variant="standard"  name="email" fullWidth onChange={handleChange} required helperText="Must be a Valid Email" />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Emp No" variant="standard"  name="empNo" fullWidth onChange={handleChange} required  helperText="Your Employee Number"/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" variant="standard"  name="password" fullWidth onChange={handleChange} required helperText="Can't be empty" />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleClick}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      </BasicLayout >
  );

}
}

export default Add;
