import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from 'constants'; // Make sure BASE_URL is correctly imported

function ImageXL(props) {
  const [accStatus, setAccStatus] = useState(props.data.Status === '1');
  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/employee/employee/${props.data}`); // Assuming props.data contains the employee ID
        setEmployeeData(res.data);
      } catch (err) {
        console.error('Error fetching employee data:', err);
      }
    };

    fetchEmployeeData();
  }, [props.data]);

  const engineerId = props.data;
  const format = '.JPG';
  const imageUrl = `${process.env.PUBLIC_URL}/assets/${engineerId}${format}`;

  return (
    <div>
      <table><thead>
      <tr >
      <th ></th>
      <th style={{ width: '20px' }}></th>
      <th ></th>
      </tr>
      <tr >
        <td>
    <div>       
      <img
        src={imageUrl}
        alt="profile-image"
        width="150"
        height="150"
        style={{
          borderRadius: '25%', // Makes the image circular
          border: '2px solid #ccc', // Adds a border with a light gray color
        }}
        onError={(e) => {
          e.target.onerror = null; // Prevents infinite loop
          e.target.src = `${process.env.PUBLIC_URL}/assets/Noimg.jpg`; // Default "no image" placeholder
        }}
      />
    </div>
    </td>
    <td>

    </td>
    <td>
    {employeeData && (
      <div>
        <p> {employeeData.name}</p>
        {employeeData.map((record, index) => (
          <p key={index}>{record.SalaryName}</p>
        ))}
      </div>      
    )}
    </td>
    </tr>
      </thead>
      </table>
  </div>
  );
}

export default ImageXL;
