import * as React from 'react';
import { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import MDButton from 'components/MDButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import { AuthContext } from 'context/authContext';
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
import axios from "axios";
import { BASE_URL } from "constants";
function FormDialog(props) {
 
  const {currentUser,login } = useContext(AuthContext);
  const [inputs, setInputs] = useState({
    userId:currentUser.id,
    userName:currentUser.username,
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [ setError] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null); 
  };

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs.newPassword !== inputs.confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }
    
    try {
      await login(inputs)
      setLoading(true);
    console.log(login);
      await axios.post(`${BASE_URL}/auth/changePassword/`, inputs);
     
      setInputs({
        userId:"",
        userName: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      });
      setOpen(false);
      setError(null); // Reset the error state on success
    } catch (err) {
      setError(err.response?.data || "An error occurred while changing the password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MDButton type="button"  size="large" onClick={handleClickOpen}>
        <Icon>
          <span class="material-symbols-outlined">manage_accounts</span>
        </Icon>
      </MDButton>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText> Change Password </DialogContentText>

          <div>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Current Password"
                variant="standard"
                name="password"
                fullWidth
                value={inputs.password}
                onChange={handleChange}
                required
                helperText="Can't be empty"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="New Password"
                variant="standard"
                name="newPassword"
                fullWidth
                value={inputs.newPassword}
                onChange={handleChange}
                required
                helperText="Can't be empty"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm Password"
                variant="standard"
                name="confirmPassword"
                fullWidth
                value={inputs.confirmPassword}
                onChange={handleChange}
                required
                helperText="Can't be empty"
              />
            </MDBox>
          </div>
          <Grid item xs={12} md={10} lg={3}></Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FormDialog;