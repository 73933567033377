import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material"; // Replace with desired icons
import { useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import { useMaterialUIController, setLayout, setMiniSidenav } from "context";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  const toggleMiniSidenav = () => {
    setMiniSidenav(dispatch, !miniSidenav);
  };

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
        transition: transitions.create(["margin-left", "margin-right"], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
        "&:hover": {
          "& > button": {
            opacity: 1,
          },
        },

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
        },
      })}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {isMouseOver && (
        <Tooltip title={miniSidenav ? "Expand" : "Minimize"} placement="right">
          <IconButton
            onClick={toggleMiniSidenav}
            sx={{ position: "absolute", top: "10px", left: "10px", zIndex: 999, opacity: 0, transition: "opacity 0.3s ease" }}
          >
            {miniSidenav ? <ArrowForward /> : <ArrowBack />}
          </IconButton>
        </Tooltip>
      )}
      {children}
    </MDBox>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;