import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "constants";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/indexImag";
import MDBox from "components/MDBox";
import ProjectSumm from "./projectSumm";
import MDButton from "components/MDButton";
import ProjectEngVice from "./projectEngVice";

function ProjectEng() {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchStock = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/project/ProjectEngVice`);
        setRecords(res.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchStock();
  }, []);
console.log(records);

  const format = '.JPG';
  
  return (
    <>
  <div>
  Engineers
  </div>
  <br/>
  <br/>
 
    
  <MDBox py={3}>
  <Grid container spacing={3}>
    {records.map((record, index) => (
      <Grid key={index} item xs={12} md={6} lg={3}>
       <a href={`/projectEngVice/${record.Engineer}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <MDBox mb={1.5}>          
                  <ComplexStatisticsCard
                    color=""
                    imageSrc={`${process.env.PUBLIC_URL}/assets/${record.Engineer}${format}`} // Pass the engineerId as props
                    title={record.ProjectEngineer}
                   // count={<ProjectSumm data={record} />}
                    count={<ProjectSumm data={record} />}
                    percentage={{
                      color: "",
                      amount: "",
                      label: `${record.SalaryName.toUpperCase()}`,
                    }}
                  />          
                </MDBox>
              </a>
      </Grid>
    ))}
  </Grid>
</MDBox>

        
      </>
  );
}

export default ProjectEng;
