import * as React from 'react';
import { useState,useContext } from "react";
import axios from "axios";
import { useEffect } from "react";
import Button from '@mui/material/Button';
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MDButton from "components/MDButton";
import { DataGrid } from '@mui/x-data-grid';
import { AuthContext } from 'context/authContext';
export default function FormDialog(props) {
  let ProjectID=props.data.ProjectID
  let ProjectName=props.data.ProjectName
  let Customer=props.data.Customer
  let ProjectEngineer=props.data.ProjectEngineer
  let EngineerName=props.data.EngineerName
  const { currentUser, logout } = useContext(AuthContext);
  const [fileRequested, setFileRequested] = useState({});
  const [recode, setrecode] = useState({
    FileName: "",
    DWGNumber: "",
    RequestedPersonID: "",
   
    SendingStatus: "0",
  });
 
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const[records,setRecord] = useState([])
  useEffect(() => {
    const fetchStok = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/project/projectFiles/${ProjectID}`);
        setRecord(res.data);        
      } catch (err) {
        console.log(err);
      }
    };
    fetchStok();
  }, []);

  const handleRequestClick = (DWGNumber) => {
    // Find the record associated with the clicked row
    const clickedRecord = records.find(record => record.DWGNumber === DWGNumber);

    // Prepare the data to be inserted
    const requestData = {
      FileName: clickedRecord.DWGName,
      DWGNumber: clickedRecord.DWGNumber,
      RequestedPersonID: currentUser.id,
     
      SendingStatus: "0",
    };

    // Insert data into the server
    axios.post(`${BASE_URL}/project/FileRequest`, requestData)
      .then(response => {
        console.log('Data inserted successfully:', response.data);
        // Optionally, update the UI or perform additional actions
        setFileRequested(prevState => ({ ...prevState, [DWGNumber]: true }));
      })
      .catch(error => {
        console.error('Error inserting data:', error);
      });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    { field: 'PartNumber', headerName: 'Part Number', width: 220 },
    { field: 'DWGCategory', headerName: 'DWG Category', width: 150 }, 
    { field: 'DWGName', headerName: 'DWG Name', width: 400 },          
    { field: 'DWGNumber', headerName: 'DWG Number' ,  width: 120},
    { field: 'Request',headerName: 'Request',          
        renderCell: (cellValues) => {
          return (
            <Button variant="contained" color="primary"  onClick={() => handleRequestClick(cellValues.row.DWGNumber)}style={{ color: "white" }}>Request</Button>
          );
        }, 
      width: 75
    },
    { field: 'IssuingDetails', headerName: 'Issuing Details', width: 200, 
      renderCell: (cellValues) => {
        const isRequested = fileRequested[cellValues.row.DWGNumber];
        return isRequested ? 'Send file to your email' : '';
      }
    },
  ];
  
  const rows = records.map((output,index)=> ({
    id: index+1,
    PartNumber:output.PartNumber,
    DWGCategory: output.DWGCategory,
    DWGName:output.DWGName,
    DWGNumber: output.DWGNumber,
  }));
  
  console.log(records);

  return (
    <>
      <MDButton type="button" variant="gradient" color="info" size="large" onClick={handleClickOpen}>
        {props.data.UsedBOM} More
      </MDButton>   
       
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
        Project  :   {ProjectName}  <br/>   
        Engineer :   {EngineerName}
          <div>
          
            <DataGrid  
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20]}
              checkboxSelection
            />   
          </div>  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
